import React, {memo, useCallback, useEffect, createRef, useState} from 'react';
import {Card, CardBody, Col, Row} from 'reactstrap';

function CloseIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="30px" height="30px">
            <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"/>
        </svg>
    )
}

function ImageGroup({ brand, getDataChild }) {
    const { logo, image1, image2, image3  } = brand;
    const wrapper = createRef(null);
    const [ imagesList, setImagesList ] = useState({
        logo: '',
        image1: '',
        image2: '',
        image3: '',
    });

    useEffect(() => {
        setImagesList({
            logo,
            image1,
            image2,
            image3
        })
    }, [logo, image1, image2, image3]);

    const handleClose = useCallback((name) => {
        setImagesList(el => ({...el, [name]: ''}));
        if(wrapper.current) wrapper.current.querySelector(`input[name="${name}"]`).value = '';
    }, [imagesList, wrapper ]);

    const handleEvent = useCallback(e => {
        e.persist()
        const { target } = e
        const { name } = target;

        setImagesList(item => ({...item, [name]: target.files[0] }));
    }, [ imagesList ]);

    console.log(imagesList);

    useEffect(() => getDataChild(imagesList), [imagesList]);

    return (
        <div ref={wrapper}>
            <Card>
                <CardBody>
                    <div className="d-inline card-title">Logo</div>
                    <Row>
                        <Col lg={6} xs={12}>
                            <label className="">Logo</label>
                            <br/>

                            {
                                imagesList.logo ?   <div onClick={() => handleClose('logo')} style={{
                                    position: 'absolute',
                                    top: 25,
                                    padding: 0,
                                    right: 0,
                                    border: 0
                                }}>
                                    <CloseIcon />
                                </div> : ''
                            }

                            <input type="file"
                                   onChange={handleEvent}
                                   name="logo"
                                   className="mb-3 w-75"/>
                            {
                                (imagesList.logo &&  typeof imagesList.logo !== 'object') &&
                                <div className="flex justify-center  mt-3">
                                    <img src={imagesList.logo}
                                         className="img-thumbnail brnd-logo"
                                         alt={'logo'}
                                    />
                                </div>
                            }
                        </Col>

                        <Col lg={6} xs={12}>
                            <label className="">Image 1</label>
                            <br/>
                            {
                                imagesList.image1 ?   <div onClick={() => handleClose('image1')} style={{
                                    position: 'absolute',
                                    top: 25,
                                    padding: 0,
                                    right: 0,
                                    border: 0
                                }}>
                                    <CloseIcon />
                                </div> : ''
                            }

                            <input type="file"
                                   onChange={handleEvent}
                                   name="image1"
                                   accept=".jpg, .png"
                                   className="mb-3 w-75"/>

                            {
                                (imagesList.image1 && typeof imagesList.image1 !== 'object') &&
                                <div className="flex justify-center  mt-3">
                                    <img src={imagesList.image1}
                                         className="img-thumbnail brnd-logo" />
                                </div>
                            }
                        </Col>

                        <Col lg={6} xs={12}>
                            <label className="">Image 2</label>
                            <br/>

                            {
                                imagesList.image2 ?   <div onClick={() => handleClose('image2')} style={{
                                    position: 'absolute',
                                    top: 25,
                                    padding: 0,
                                    right: 0,
                                    border: 0
                                }}>
                                    <CloseIcon />
                                </div> : ''
                            }
                            <input type="file"
                                   onChange={handleEvent}
                                   name="image2"
                                   accept=".jpg, .png"
                                   className="mb-3 w-75"/>
                            {
                                (imagesList.image2 && typeof imagesList.image2 !== 'object') &&

                                <div className="flex justify-center  mt-3">
                                    <img src={imagesList.image2}
                                         className="img-thumbnail brnd-logo" />
                                </div>
                            }
                        </Col>

                        <Col lg={6} xs={12}>
                            <label className="">Image 3</label>
                            <br/>
                            {
                                imagesList.image3 ?   <div onClick={() => handleClose('image3')} style={{
                                    position: 'absolute',
                                    top: 25,
                                    padding: 0,
                                    right: 0,
                                    border: 0
                                }}>
                                    <CloseIcon />
                                </div> : ''
                            }
                            <input type="file"
                                   onChange={handleEvent}
                                   name="image3"
                                   accept=".jpg, .png"
                                   className="mb-3 w-75"/>
                            {
                                (imagesList.image3 &&  typeof imagesList.image3 !== 'object') &&
                                <div className="flex justify-center  mt-3">
                                    <img src={imagesList.image3}
                                         className="img-thumbnail brnd-logo" />
                                </div>
                            }
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    )
}

export default memo(ImageGroup);
