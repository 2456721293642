import React, {Component} from 'react';
import debounce from 'lodash/debounce';
import { PropTypes, array, bool } from 'prop-types';
import { Field, FieldArray, FormSection, reduxForm } from 'redux-form'
import Keyword from '../../shared/components/form-section/Keyword';

import { Form, Input, Button, Card, CardColumns, CardTitle, CardBody, CardHeader, Col, Row, Label } from 'reactstrap';
import Snackbar from '@material-ui/core/Snackbar';

import SimpleLineIcon from 'react-simple-line-icons';
import { renderField, renderSelectField, renderCheckbox, renderMultipleCheckbox, renderMembers, renderCombobox, renderMultiselect } from '../../shared/components/form-field/ReduxFormFields';

import { reset } from 'redux-form';
import { SubmissionError } from 'redux-form'
import { stopSubmit } from 'redux-form'
import { change } from "redux-form";

import { getContractType, addContractType, updateContractType, resetContractType, getContractTypes, } from '../../actions/contractTypeActions';
import { checkAuth } from '../../actions/sessionActions';
import { validate } from './validate';

import './ContractTypeForm.css';

import { comparer } from '../../shared/utils/helpers.js';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Redux Store
import configureStore from '../../shared/redux/configureStore';
import {backOnListPage} from '../../shared/utils/backOnListPage';
// Configuring Redux Store
const store = configureStore(window.initialState);

const messages = {
  emptyFilterList: 'No results. Try another query'
}

class ContractTypeForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      open: false,
      create: false,
      clearChangesEnabled: true,
      selectedContractType: null
    }
    this.submit = this.submit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleParentContractTypeChange = this.handleParentContractTypeChange.bind(this);
    this.handleContractTypeSearch = this.handleContractTypeSearch.bind(this);
  }

  componentDidMount(props) {
    console.log('componentDidMount')
    const {getContractType, addContractType, updateContractType, getContractTypes} = this.props;
    if(this.state.id) {
      getContractType(this.state.id);
    }
    getContractTypes();
  }

  componentDidUpdate(prevProps) {
    console.log('componentDidUpdate')
    if(!this.props.match.params.id) {
      if(!this.state.create) {
        //this.props.dispatch(reset('contractTypeForm'));  // requires form name
        this.props.initialize();
        store.dispatch(resetContractType());
        this.setState({create: true});
      }
    }
    if(this.props.match.params.id) {
      if(this.state.selectedContractType==null && typeof(this.props.contractType)!='undefined' && this.props.contractType.parent_id!=null) {
        this.setState({selectedContractType: this.props.contractType.parent_id});
      }
    }
  }

  // Save ContractType
  submit(values) {

    console.log('---submit');
    console.log(values);

    var action;
    if(typeof(values.created_at)!='undefined') {
      delete values.created_at
    }
    // if(typeof(values.parent_id)!='undefined') {
    //   console.log('values.parent_id')
    //   console.log(values.parent_id)
    //   values.parent_id = values.parent_id.id;
    //   if(values.parent_id==0) {
    //     values.parent_id = null;
    //     delete values.keywords;
    //   }
    // }
    // if(values.parent_id==null) {
    //   delete values.keywords;
    // }
    if(typeof(values.updated_at)!='undefined') {
      delete values.updated_at
    }
    if(this.state.create) {
      action = addContractType(values);
    }
    else {
      action = updateContractType(values);
    }
    store.dispatch(action).then((result) => {
      if(typeof(result)!='undefined') {
        this.setState({open: true, clearChangesEnabled: false});
       // window.location.reload();
       // this.props.initialize();
       this.props.getContractType(this.state.id);
       console.log(this.props.history);

        backOnListPage(this.props.history, '/contract-types/contract-types')
      }
  	}).catch((error) => {
  		throw new SubmissionError({_error:  error });
  	});
  }

  handleParentContractTypeChange(e) {
    console.log('handleParentContractTypeChange');
    console.log(e.id);
    this.setState({selectedContractType: (e=='' || e.id==0) ? 0 : e});
    if(typeof(e.id)=='undefined') {
      this.handleContractTypeSearch(e);
    }
  }

  handleContractTypeSearch = debounce((searchTerms) => {
    this.contractTypeSearch(searchTerms);
  }, 500);

  contractTypeSearch(searchTerms) {
    console.log('handleContractTypeSearch');
    var params = {}
    var search = {}
    search['search[name]'] = encodeURIComponent(searchTerms.trim());
    Object.assign(params, search);
    store.dispatch(getContractTypes(params)).then((result) => {
      var contractTypeSearchResult = result.payload.items.map(contractType => ({ id: contractType.id, name: contractType.name }));
      this.setState({contractTypeSearchResult: contractTypeSearchResult});
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });
  }

  handleClose() {
    this.setState({open: false});
  }

  handleClearChanges = () => {
    this.props.reset();
  };

  render() {

    const session = this.props.session
    if(typeof(session.forceLogout)!="undefined" && session.forceLogout==1) {
      this.setState({session: null});
      window.location.href="/login";
    }

    const { input, error, handleSubmit, pristine, reset, submitting, message, contractTypes, contractType } = this.props

    console.log('---render');

    var selectedContractType = '';
    if(typeof(contractType)!='undefined') {
      selectedContractType = contractType.parent_id;
    }
    if(this.state.selectedContractType==null) {
      selectedContractType = '';
    }
    else if(this.state.selectedContractType!='') {
      selectedContractType = this.state.selectedContractType;
    }
    console.log('this.state.selectedContractType');
   console.log(this.state.selectedContractType);
    var contractTypesArr = [{id: 0, name: ''}];
    contractTypesArr = contractTypesArr.concat(contractTypes.filter(item=>(item.parent_id==null)).map(contractType => ({ id: contractType.id, name: contractType.name?.en || contractType.name })));
    if(this.state.contractTypeSearchResult!=null) {
      contractTypesArr = this.state.contractTypeSearchResult;
    }
    if(typeof(contractType)!='undefined' && typeof(contractTypes) && contractTypes!=null) {
      for(var i=0; i<contractTypesArr.length; i++) {
        var contractTypeItem = contractTypesArr[i];
        const found = contractTypesArr.some(item => item.id === contractTypeItem.id);
        if(!found) {
          contractTypesArr = contractTypesArr.concat({id: contractTypeItem.id, name: contractTypeItem.name?.en || contractTypeItem.name});
        }
      }
    }


    var contractTypesSingleSelectArr = contractTypes.map(contractType => ({ value: contractType.id, text: contractType.name?.en || contractType.name }));

    return (
      <div className="animated fadeIn mt-5">
        <form onSubmit={handleSubmit(this.submit)}>
          <Card>
            <CardHeader><i className="fa fa-align-justify"></i> Contract Type details</CardHeader>
            <CardBody>
              <CardColumns className="form-sections">
                <Card>
                  <CardBody>
                  <CardTitle className="d-inline">Contract Type</CardTitle>
                    <Row>
                        <Col xs="12">
                          <Field
                            name="parent_id"
                            type="select"
                            component={renderCombobox}
                            label="Parent ID - type to search Contract Type e.g. Insurance"
                            data={contractTypesArr}
                            defaultValue={selectedContractType}
                            valueField="id"
                            textField="name"
                            filter={true}
                            className="p-0 pr-2"
                            onChange={(event) => this.handleParentContractTypeChange(event)}
                            /*disabled={[{id:0, name: '...'}]}*/
                          />
                        </Col>
                    </Row>
                    <Field
                      name="name.de"
                      type="text"
                      component={renderField}
                      label="Name DE"
                      className="form-control"
                    />
                    <Field
                      name="name.en"
                      type="text"
                      component={renderField}
                      label="Name EN"
                      className="form-control"
                    />
                  </CardBody>
                </Card>

                {/*{this.state.selectedContractType!=null && this.state.selectedContractType!=0 &&*/}
                  <Keyword />
                {/*}*/}
              </CardColumns>
              <Row>
                 <Col xs="12">
                  {/*<Field name="active" component={renderCheckbox} color="primary" label="Active" />*/}
                  {!!error && <div className="form-error mt-3">{error && <strong>{error}</strong>}</div>}
                  <button type="submit" className="btn btn-primary mt-3" disabled={pristine || submitting}>Submit</button>
                  {/*<button type="button" className="btn btn-light mt-5 ml-2" disabled={!this.state.clearChangesEnabled} onClick={this.handleClearChanges}>Clear changes</button>*/}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </form>
        <Snackbar open={this.state.open} autoHideDuration={2000} message="Saved" onClose={() => this.setState({open: false})}
          action={
            <React.Fragment>
              <a className="text-white cursor-pointer mr-2" aria-label="close" onClick={this.handleClose}><i className="cil-x"></i></a>
            </React.Fragment>
          }
        />
      </div>
    )
  }
}

ContractTypeForm.propTypes = {
  pristine: PropTypes.bool,
  message: PropTypes.string,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  contractType: PropTypes.object,
  contractTypes: PropTypes.array,
  session: PropTypes.any
};

function mapStateToProps(state) {
  console.log('STATE');
  console.log(state);

  if (
    (state?.form?.contractTypeForm?.values?.name_translations?.en && !state?.form?.contractTypeForm?.values?.name?.en) ||
    (state?.form?.contractTypeForm?.values?.name_translations?.de && !state?.form?.contractTypeForm?.values?.name?.de)
  ) {
    state.form.contractTypeForm.values.name = state.form.contractTypeForm.values.name_translations
  }

  return {
    //formData: state.contractTypes,
    initialValues: typeof(state.contractTypeForm)!=='undefined' ? state.contractTypeForm.contractType : null,
    contractType: typeof(state.contractTypeForm)!=='undefined' ? state.contractTypeForm.contractType : null,
    contractTypes: (typeof(state.contractTypes)!=='undefined' && typeof(state.contractTypes.result)!=='undefined') ? state.contractTypes.result.items : [],
    session: state.session
  }
};

const mapDispatchToProps = dispatch => bindActionCreators({change, getContractType, addContractType, updateContractType, resetContractType, getContractTypes, checkAuth}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({
   form: 'contractTypeForm', // a unique identifier for this form,
   enableReinitialize: true,
   validate
})(ContractTypeForm))