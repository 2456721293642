import React, {memo, useCallback, useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Form, Input, Row} from 'reactstrap';
import BrandCombobox from '../../shared/components/searchable-field/BrandCombobox';
import DropdownList from 'react-widgets/lib/DropdownList';
import DataTable from 'react-data-table-component';
import MaterialCheckbox from '../../shared/components/material-checkbox/MaterialCheckbox';
import DataTableProgressComponent from '../../shared/components/widget/DataTableProgressComponent';
import DataTableNoDataComponent from '../../shared/components/widget/DataTableNoDataComponent';
import {getClicks} from '../../actions/usersActions';
import {getBanks} from '../../actions/brandUpdateActions';
import memoize from 'memoize-one';

function Click(props) {
    const [banksList, setBanksList] = useState([]);
    const [ clicks , setClicks ] = useState({ items: [], meta: {}});
    const getClicksAction = useCallback(async (params) => {
        const request = await getClicks(params);
        setClicks(await request);
    }, []);

    const handleBankChange = useCallback(({ id }) => {
        getClicksAction({ bank_id: id })
    }, []);

    const getBanksAction = useCallback(async () => {
        const request = await getBanks();

        setBanksList(await request);
    }, []);

    const columns = memoize(() => [
        {
            name: 'ID',
            selector: 'id',
            sortable: false,
            grow: 0,
        },
        {
            name: 'Date',
            selector: 'created_at',
            sortable: false,
            grow: 1,
            cell: row => row?.created_at?.split('T')[0]
        },
        {
            name: 'Partner',
            selector: 'brand',
            sortable: false,
            grow: 1,
            cell: row => row.brand.name
        },
    ]);


    useEffect(() => {
        getBanksAction();
    }, []);

    const { items , meta } = clicks;

    return (
        <Row>
            <Col xs="12" lg="12">
                <Row>
                    <Col xs="12" lg="2">
                        <DropdownList filter
                                      placeholder="Choose Bank"
                                      data={[{id:'0', name: 'Choose Bank'}].concat(banksList)}
                                      valueField="id"
                                      textField="name"
                            // defaultValue={{id: this.state.selectedBank!=null ? this.state.selectedBank: 0, name: (this.state.selectedBank!=null && banksArr.length>0) ? banksArr.find(b => b.id == this.state.selectedBank).name : 'Choose Bank'}}
                                      onChange={handleBankChange}
                                      className="mb-4"
                        />
                    </Col>
                </Row>
            <Card>
                <CardHeader>
                    <div className="d-flex align-items-center justify-content-between">
                        <div><i className="fa fa-align-justify"></i> Clicks list</div>
                    </div>
                </CardHeader>
                <CardBody>
                    <DataTable
                        noHeader={false}
                        data={items}
                        columns={columns()}
                        theme="solarized"
                        progressPending={false}
                        pagination
                        paginationServer
                        paginationTotalRows={meta?.total_items}
                        paginationRowsPerPageOptions={[10,15,30,50]}
                        // onChangeRowsPerPage={this.handlePerRowsChange}
                        // onChangePage={this.handlePageChange}
                        // onSort={this.handleSort}
                        sortServer
                        selectableRows
                        selectableRowsComponent={MaterialCheckbox}
                        // selectableRowsComponentProps={selectProps}
                        // onSelectedRowsChange={handleChange}
                        progressComponent={<DataTableProgressComponent />}
                        noDataComponent={<DataTableNoDataComponent />}
                    />
                </CardBody>
            </Card>
        </Col>
        </Row>
    );
}

export default memo(Click);