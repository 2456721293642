export function getSearchParams(history) {
    const searchParams = new URLSearchParams(history.location.search);

    console.log(searchParams);

    const page = searchParams.get('page') || 1;
    const perPage = searchParams.get('perPage') || 10;

    console.log(page, perPage)

  return { page: +page, perPage: +perPage }
}