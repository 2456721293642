import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { PropTypes, array, bool } from 'prop-types';
import { Form, Input, Button, Badge, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MaterialCheckbox from '../../shared/components/material-checkbox/MaterialCheckbox';
import SimpleLineIcon from 'react-simple-line-icons';
import { renderCombobox } from '../../shared/components/form-field/ReduxFormFields';
import BrandCombobox from '../../shared/components/searchable-field/BrandCombobox';
import CountryCombobox from '../../shared/components/searchable-field/CountryCombobox';
import CategoryCombobox from '../../shared/components/searchable-field/CategoryCombobox';
import DataTableProgressComponent from '../../shared/components/widget/DataTableProgressComponent';
import DataTableNoDataComponent from '../../shared/components/widget/DataTableNoDataComponent';
import DropdownList from 'react-widgets/lib/DropdownList';
import 'react-widgets/dist/css/react-widgets.css';
import { CSVLink } from 'react-csv'

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import './Modal.css';
import './Coupons.css';
import CouponShow from './CouponShow';

import memoize from 'memoize-one';
import DataTable, { createTheme } from 'react-data-table-component';

import { checkAuth } from '../../actions/sessionActions';
import { getCoupon, getCoupons, deleteCoupon } from '../../actions/couponActions';

import { SubmissionError } from 'redux-form'

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Redux Store
import configureStore from '../../shared/redux/configureStore';
import {historyPagination} from '../../shared/utils/historyPagination';
import {getSearchParams} from '../../shared/utils/getSearchParams';
import {getBrands} from '../../actions/brandActions';
// Configuring Redux Store
const store = configureStore(window.initialState);

const columns = memoize((handleEditClick, handleShowClick, handleDeleteClick) => [
  {
    name: 'ID',
    selector: 'id',
    sortable: true,
    center: true,
    grow: 0
  },
  {
    name: 'Brand',
    sortable: false,
    grow: 1,
    cell: row => <div>{row.brand!=null ? row.brand.name : ''}</div>
  },
  {
    name: 'Title',
    selector: 'title',
    sortable: true,
    grow: 1.5
  },
  {
    name: 'Code',
    selector: 'code',
    sortable: false,
    right: false,
    grow: 1
  },
  {
    name: 'Start date',
    sortable: true,
    cell: row => <div>{row.start_date!=null ? row.start_date.substring(0, 10) : ''}</div>,
    grow: 1
  },
  {
    name: 'End date',
    sortable: true,
    grow: 1,
    cell: row => <div>{row.end_date!=null ? row.end_date.substring(0, 10) : ''}</div>
  },
  {
    name: 'Amount',
    selector: "amount",
    sortable: true,
    center: true,
    grow: 1,
    cell: row => <div>{row.amount + '' + row.unit}</div>
  },
  {
    name: 'Unit',
    selector: 'unit',
    sortable: true,
    center: true,
    grow: 1
  },
  {
    name: 'Status',
    selector: 'active',
    sortable: false,
    cell: row => <span>{row.active==true ? 'Active' : 'Inactive'}</span>,
    center: true,
    grow: 1
  },
  {
    name: 'Actions',
    sortable: false,
    left: true,
    cell: (row) => <div>
                     <button className="btn btn-sm btn-light mr-2" onClick={handleEditClick} id={row.id}><i className="icon-pencil" onClick={handleEditClick} id={row.id}></i></button>
                     <button className="btn btn-sm btn-light mr-2" onClick={handleDeleteClick} id={row.id}><i className="icon-trash" id={row.id}></i></button>
                     <button className="btn btn-sm btn-light mr-2" onClick={handleShowClick} id={row.id}><i className="icon-chart" onClick={handleShowClick} id={row.id}></i></button>
                    </div>,
    ignoreRowClick: true
  }
]);


const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

class Coupons extends Component {

  state = {
    data: [],
    loading: false,
    totalRows: 0,
    perPage: 10,
    sort: false,
    search: null,
    modalOpen: false,
    coupon: null,
    modalTitle: null,
    error: null,
    searchFields: {},

    isResetForm: false,
  };

  constructor(props) {
    super(props);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleShowClick = this.handleShowClick.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.onSearchInputChange = this.onSearchInputChange.bind(this);
    this.onSearchableInputChange = this.onSearchableInputChange.bind(this);
  }

  componentDidMount(props) {
    this.setState(items => ({...items, loading: true }));

    const { perPage = this.state.perPage , page = 0 } = getSearchParams(this.props.history)
    var params = {}
    params.per_page = perPage;
    params.page = page

    store.dispatch(getCoupons(params)).then((result) => {
      this.setState(items => ({
        ...items,
        data: result.payload.items,
        totalRows: result.payload.meta.total_items,
        loading: false,
        perPage,
        page
      }));
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalTitle: 'Error!',
        modalOpen: true
      });
    });
    /* JWT is stored in memory */
    /*
    const {session} = this.props
    if(typeof(session.jwt)=='undefined') {
      sessionStorage.removeItem('jwt');
      this.setState({session: null});
      this.props.history.push({pathname: '/login'});
      window.location.href="/login";
    }
    */
  }

  componentDidUpdate() {
    if(!this.props.location.search) {
      const { perPage = this.state.perPage , page = 0 } = getSearchParams(this.props.history)
      var params = {}
      params.per_page = perPage;
      params.page = page

      store.dispatch(getCoupons(params)).then((result) => {
        this.setState(items => ({
          ...items,
          data: result.payload.items,
          totalRows: result.payload.meta.total_items,
          loading: false,
          perPage,
          page
        }));

        historyPagination(this.props.history, page, perPage) ;
      }).catch((error) => {
        //throw new SubmissionError({_error:  error });
        this.setState({
          error: error,
          modalTitle: 'Error!',
          modalOpen: true
        });
      });
    }
  }

  handleFindCoupons = async (e) => {
    e.preventDefault();
    console.log('handleFindCoupons');
    console.log(this.state);
    if(this.state.search && this.state.search.toString().length>1) {
      this.setState({ loading: true });
      var params = {}
      params.per_page = this.state.perPage;
      if(this.state.sort) {
        Object.assign(params, this.state.sort)
      }
      if(this.state.search) {
        Object.assign(params, this.state.search)
      }
      store.dispatch(getCoupons(params)).then((result) => {
        this.setState({
          loading: false,
          data: result.payload.items,
          totalRows: result.payload.meta.total_items
      });
      }).catch((error) => {
        //throw new SubmissionError({_error:  error });
        this.setState({
          error: error,
          modalOpen: true
        });
      });
    }
  }

  handlePageChange = async page => {
    console.log('handlePageChange');
    this.setState(items => ({...items, loading: true }));
    const { perPage } = this.state;
    var params = {}
    params.per_page = perPage;
    params.page = page;
    if(this.state.sort) {
      Object.assign(params, this.state.sort)
    }
    if(this.state.search) {
      Object.assign(params, this.state.search)
    }
    store.dispatch(getCoupons(params)).then((result) => {
      this.setState(items => ({
        ...items,
        loading: false,
        data: result.payload.items,
      }));

      historyPagination(this.props.history, page, perPage) ;
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });

  }

  handlePerRowsChange = async (perPage, page) => {
    console.log('handlePerRowsChange');
    this.setState(item => ({...item, loading: true }));
    var params = {}
    params.per_page = perPage;
    params.page = page;
    if(this.state.sort) {
      Object.assign(params, this.state.sort)
    }
    if(this.state.search) {
      Object.assign(params, this.state.search)
    }
    store.dispatch(getCoupons(params)).then((result) => {
      this.setState(items => ({
        ...items,
        loading: false,
        data: result.payload.items,
        perPage: perPage,
    }));

      historyPagination(this.props.history, page, perPage);
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });
  }

  handleSort = async (column, sortDirection) => {
    console.log('handleSort');
    this.setState({ loading: true });
    var params = {}
    params.per_page = this.state.perPage;
    var sort = {}
    sort['order['+column.selector+']'] = sortDirection;
    Object.assign(params, sort)
    if(this.state.search) {
      Object.assign(params, this.state.search)
    }
    store.dispatch(getCoupons(params)).then((result) => {
      this.setState({
        loading: false,
        data: result.payload.items,
        sort: sort
    });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });
  };

  handleEditClick(event) {
    console.log('handleEditClick');
    this.props.history.push({pathname: '/coupons/edit/'+event.target.id});
  }

  handleShowClick(event) {
    console.log('handleShowClick');
     store.dispatch(getCoupon(event.target.id)).then((result) => {
      if(typeof(result)!='undefined') {
        console.log(result);
        var coupon = result.payload;
        this.setState({
          coupon: coupon,
          modalTitle: coupon.title?.en || coupon.title
        });
        this.setState({modalOpen: true});
      }
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });

  }

  handleDeleteClick(event) {
    console.log('handleDeleteClick');
    if(window.confirm('Are you sure you want to delete this coupon?')) {
      store.dispatch(deleteCoupon(event.target.id)).then((result) => {
        if(typeof(result)!='undefined') {
          var coupon = result.payload;
          window.location.reload();
        }
      }).catch((error) => {
        //throw new SubmissionError({_error:  error });
        this.setState({
          error: error,
          modalOpen: true
        });
      });
    }
  }

  onSearchInputChange = (evt,field) => {
    const search = Object.assign({}, this.state.search);
    if(typeof(field)!='undefined') {
      if(field=='active') {
        if(evt=='Active') {
          var evt = {
            target: {name: 'active', value: '', checked: true}
          }
        }
        if(evt=='Inactive') {
          var evt = {
            target: {name: 'active', value: '', checked: false}
          }
        }
        if(evt=='Status') {
          var evt = {
            target: {name: 'active', value: '', checked: null}
          }
        }
      }
      if(field=='targeted') {
          if(evt=='Yes') {
            var evt = {
              target: {name: 'targeted', value: '', checked: true}
            }
          }
          if(evt=='No') {
            var evt = {
              target: {name: 'targeted', value: '', checked: false}
            }
          }
          if(evt=='Any' || evt=='Targeted') {
            var evt = {
              target: {name: 'targeted', value: '', checked: null}
            }
          }
        }
    }
    if(evt.target.value!='') {
      search['search['+evt.target.name+']'] = encodeURIComponent(evt.target.value);
    }
    else if(evt.target.name=='active' && evt.target.checked!=null) {
      search['search['+evt.target.name+']'] = evt.target.checked;
    }
    else if(evt.target.name=='active' && evt.target.checked==null) {
      delete search['search['+evt.target.name+']'];
    }
    else if(evt.target.name=='targeted' && evt.target.checked!=null) {
      search['search['+evt.target.name+']'] = evt.target.checked;
    }
    else if(evt.target.name=='targeted' && evt.target.checked==null) {
      delete search['search['+evt.target.name+']'];
    }
    else {
      delete search['search['+evt.target.name+']'];
    }
    this.setState({search});
  }

  onSearchableInputChange(name, value) {
    console.log('onSearchableInputChange');
    const search = Object.assign({}, this.state.search);
    if(value!='') {
      this.setState({isResetForm: false})
      search['search['+name+']'] = encodeURIComponent(value);
    }
    else if(name=='active') {
      search['search['+name+']'] = value; // true/false
    }
    else {
      delete search['search['+name+']'];
    }

    this.setState({search});
  };

  handleModalOpen() {
    this.setState({modalOpen: true});
  }

  handleModalClose() {
    this.setState({modalOpen: false})
  }

  resetSearch(e) {
    e.preventDefault()
    console.log('resetSearch')
    this.setState({search: {}})

    document.querySelector('.--search-form').reset()

    if (document.querySelector('.--status .rw-list li'))
      document.querySelector('.--status .rw-list li').click()
    if (document.querySelector('.--featured .rw-list li'))
      document.querySelector('.--featured .rw-list li').click()

    this.setState({search: {}})
    this.setState({isResetForm: true})
    setTimeout(() => this.handleFindCoupons(e))

    setTimeout(() => {
      const $input = document.querySelector('.--search-form input[name="brand"]')
      $input.value = ''
    },)
  }

  render() {

    const session = this.props.session
    if(typeof(session.forceLogout)!="undefined" && session.forceLogout==1) {
      this.setState({session: null});
      window.location.href="/login";
    }

    const { loading, data, totalRows } = this.state

    const handleChange = (state) => {
      // You can use setState or dispatch with something like Redux so we can use the retrieved data
      console.log('Selected Rows: ', state.selectedRows);
    };

    const headers = Object.keys((data || [])[0] || {}).map(key => {
      return { label: key.replaceAll('_', ' ').toUpperCase(), key }
    })

    const csvData = data.map(item => {
      let csvItem = {}
      Object.keys(item)
        .forEach(key => csvItem[key] = item[key]?.id || item[key])
      return csvItem
    })

    return (
      <div className="animated fadeIn mt-5">
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          id="modal"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.modalOpen}
          onClose={this.handleModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.modalOpen}>
            <div className="material-modal-content">
              <h4 id="transition-modal-title">{this.state.modalTitle} <i className="cil-x cursor-pointer float-right" onClick={this.handleModalClose}></i></h4>
              <div id="transition-modal-description">
                {this.state.error!=null
                  ? <div>{this.state.error.toString()}</div>
                  : <CouponShow coupon={this.state.coupon} />
                }
              </div>
            </div>
          </Fade>
        </Modal>
        <Row>
          <Col xs="12" lg="12">
            <div className="d-flex mb-4 justify-content-end">
              {!!csvData?.length &&
              <CSVLink data={csvData}
                       headers={headers}
                       filename={"Coupons.csv"}
                       target="_blank"
                       className="mr-2">
                <Button color="secondary" className="bg-white text-dark mr-2">
                  Export CSV
                </Button>
              </CSVLink>}

              <Link to="/coupons/new">
                <Button color="secondary" className="bg-white text-dark" outline>
                  <i className="cil-plus text-dark"></i>
                </Button>
              </Link>
            </div>
          </Col>
          <Col xs="12" lg="12">
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center justify-content-between">
                  <div><i className="fa fa-align-justify"></i> Coupons list</div>
                  <div>
                    <Form className="form-inline float-right text-right --search-form" onSubmit={this.handleFindCoupons}>
                      <BrandCombobox
                        isResetForm={this.state.isResetForm}
                        onChange={this.onSearchableInputChange}
                        className="w-200" />
                      <CountryCombobox onChange={this.onSearchableInputChange} className="w-200" />
                      <CategoryCombobox onChange={this.onSearchableInputChange} className="w-200" />
                      <DropdownList
                        name="targeted"
                        onChange={(e)=>this.onSearchInputChange(e, 'targeted')}
                        data={['Targeted', 'Any', 'Yes', 'No']}
                        placeholder="Targeted"
                        className="status-dropdown text-left pr-2 --status"
                      />
                      <DropdownList
                        name="active"
                        onChange={(e)=>this.onSearchInputChange(e, 'active')}
                        data={['Status', 'Active', 'Inactive']}
                        placeholder="Status"
                        className="status-dropdown text-left --featured"
                      />

                      {/*<button className="ml-2 btn btn-outline-secondary" type="button"
                              onClick={(e)=>this.resetSearch(e)}
                      >Reset</button>*/}

                      <a onClick={(e)=>this.handleFindCoupons(e)} className="search-btn ml-1 cursor-pointer btn btn-outline-secondary"><i className="icon-magnifier"></i></a>
                    </Form>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                { data.length > 0 &&
                  <DataTable
                    noHeader={false}
                    columns={columns(this.handleEditClick, this.handleShowClick, this.handleDeleteClick)}
                    data={data}
                    theme="solarized"
                    progressPending={loading}
                    pagination
                    paginationDefaultPage={this.state.page}
                    paginationPerPage={this.state.perPage}
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                    onSort={this.handleSort}
                    sortServer
                    selectableRows
                    selectableRowsComponent={MaterialCheckbox}
                    selectableRowsComponentProps={selectProps}
                    onSelectedRowsChange={handleChange}
                    progressComponent={<DataTableProgressComponent />}
                    noDataComponent={<DataTableNoDataComponent />}
                  />
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

Coupons.propTypes = {
  accounts: PropTypes.object,
  session: PropTypes.any
}

function mapStateToProps(state) {
  console.log('STATE')
    return {
      //coupons: state.coupons,
      //data: state.coupons.coupons.items,
      session: state.session,
      accounts: state.accounts
    }
  };

const mapDispatchToProps = dispatch => bindActionCreators({getCoupon, getCoupons, checkAuth}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Coupons)