import React, {Component} from 'react';
import { PropTypes, array, bool } from 'prop-types';
import { Field, FormSection, reduxForm } from 'redux-form'


import { Form, Input, Button, Card, CardBody, CardHeader, Col, Row, Label } from 'reactstrap';
import Snackbar from '@material-ui/core/Snackbar';
import DropdownList from 'react-widgets/lib/DropdownList'

import SimpleLineIcon from 'react-simple-line-icons';
import { renderField, renderTextareaField, renderSelectField, renderCheckbox } from '../../shared/components/form-field/ReduxFormFields';

import { reset } from 'redux-form';
import { SubmissionError } from 'redux-form'
import { stopSubmit } from 'redux-form'

import { getCustomer, addCustomer, updateCustomer, resetCustomer } from '../../actions/customerActions';
import { getBrands } from '../../actions/brandActions';
import { checkAuth } from '../../actions/sessionActions';
import { getBanks } from '../../actions/bankActions'
import { validate } from './validate';

import './CustomerForm.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Redux Store
import configureStore from '../../shared/redux/configureStore';
import {backOnListPage} from '../../shared/utils/backOnListPage';
// Configuring Redux Store
const store = configureStore(window.initialState);

class CustomerForm extends Component {

  constructor(props) {
    super(props);
    this.DropdownList = React.createRef();
    this.state = {
      id: this.props.match.params.id,
      bankId: this.props.match.params.bank_id,
      open: false,
      create: false,
      clearChangesEnabled: true,
      gender: null,
      init: false
    }

    console.log(this.props, 'PROPPP')
    this.submit = this.submit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleBankChange = this.handleBankChange.bind(this)
    this.handleCreateList = this.handleCreateList.bind(this)
  }

  handleCreateList(e) {
    console.log(e,'create')
  }

  async componentDidMount(props) {

    console.log('componentDidMount');

    const { getCustomer, addCustomer, updateCustomer } = this.props;
    if(this.state.id) {
      const {payload} = await getCustomer({id: this.state.id, bank_id: this.state.bankId});
      const {profile} = payload;
      this.setState(prev => ({...prev, gender : profile.gender }))
    }

    const { getBanks } = this.props
    getBanks()
    /* JWT is stored in memory */
    /*
    const {session} = this.props
    if(typeof(session.jwt)=='undefined') {
      sessionStorage.removeItem('jwt');
      this.setState({session: null});
      this.props.history.push({pathname: '/login'});
      window.location.href="/login";
    }
    */
  }

  componentDidUpdate(preVrops) {
    console.log('componentDidUpdate')

    if(!this.props.match.params.id) {
      if(!this.state.create) {
        //this.props.dispatch(reset('customerForm'));  // requires form name
        this.props.initialize();
        store.dispatch(resetCustomer());
        this.setState(prev => ({...prev,create: true}));
      }
    }

    if ((this.props.banks || [])[0]?.id && !this.state.bankId) {
      this.setState(prev => ({...prev, bankId: this.props.banks[0].id}))
    }

    // this.setState(prev => ({...prev,init: true}));

    console.log(this.state,'state3')
  }

  // Save Customer
  submit(values) {
    var action;
    if(typeof(values.active)=='undefined') {
      values.active = false;
    }
    if(this.state.create) {
      action = addCustomer(values, this.state.bankId, this.state.gender);
    }
    else {
      console.log('action', this.state.id, values, this.state.bankId)
      action = updateCustomer(this.state.id, values, this.state.bankId , this.state.gender);
    }
    store.dispatch(action).then((result) => {
      if(typeof(result)!='undefined') {
        // this.setState({open: true});
        this.setState(prev => ({...prev,open: true,clearChangesEnabled: false}));
        backOnListPage(this.props.history, '/customers/customers')
      }

      // document.querySelector('[name="country_code"]').value = ''
  	}).catch((error) => {
  		throw new SubmissionError({_error:  error });
  	});
  }

  handleClose() {
    this.setState(prev => ({...prev,open: false}));
  }

  handleClearChanges = () => {
    this.props.reset();
  };

  handleBankChange(e) {
    console.log('---handleBankChange')

    const obj = e.gender ? {'gender': e.gender} : {'bankId': e.id}

    this.setState(prev => ({...prev, ...obj}))
  }

  render() {
    console.log('---render')

    const session = this.props.session
    if(typeof(session.forceLogout)!="undefined" && session.forceLogout==1) {
      this.setState(prev => ({...prev, session: null}));
      window.location.href="/login";
    }

    const { error, handleSubmit, pristine, reset, submitting, message, banks } = this.props


    console.log(banks, 'banks');

    const banksArr = banks.map(bank => ({ id: bank.id, name: bank.name }))
    let defaultBank = banksArr[0]
    if (this.state?.bankId) {
      [defaultBank] = banksArr.filter(bank => bank.id == this.state.bankId)
    }

    let {gender, init} = this.state;


    return (
      <div className="animated fadeIn mt-5">
        {
            <form onSubmit={handleSubmit(this.submit)}>
              <Row>
                <Col xs="12" lg="12">
                  <Card>
                    <CardHeader><i className="fa fa-align-justify"></i> Customer details</CardHeader>
                    <CardBody>
                      <Row>
                        <Col xs="12" lg="6">
                          <fieldset className="p-2 pb-5 pl-5 pr-5">
                            <legend className="d-inline">Customer</legend>
                            <Row>
                              <Col xs="12" lg="6">
                                <Field
                                    name="country_code"
                                    type="text"
                                    component={renderField}
                                    label="Country code"
                                    className="form-control"
                                />
                              </Col>
                              {defaultBank && <Col xs="12" lg="6">
                                <div className="mb-2 mr-2">
                                  <Label>Choose Bank</Label>
                                  <DropdownList filter
                                                placeholder=""
                                                data={banksArr}
                                                valueField="id"
                                                textField="name"
                                                defaultValue={defaultBank}
                                                onChange={this.handleBankChange}
                                  />
                                </div>
                              </Col>}
                            </Row>
                            <Row>
                              <Col xs="12" lg="6">
                                <Field
                                    name="birth_year"
                                    type="number"
                                    component={renderField}
                                    label="Birth year"
                                    className="form-control"
                                />
                              </Col>
                              {
                                  gender &&
                                  <Col xs="12" lg="6">
                                  <div className="mb-2 mr-2" ref={this.DropdownList}>
                                    <Label>Gender</Label>
                                    <DropdownList filter
                                                  placeholder=""
                                                  data={[
                                                    {
                                                      name: 'Male',
                                                      gender: 'male'
                                                    },
                                                    {
                                                      name: 'Female',
                                                      gender: 'female'
                                                    }
                                                  ]}
                                                  valueField="gender"
                                                  defaultValue={gender}
                                                  textField="name"
                                                  data-name={'gender'}
                                                  onChange={this.handleBankChange}
                                    />

                                  </div>
                                </Col>
                              }

                              {
                                  !gender &&
                                  <Col xs="12" lg="6">
                                    <div className="mb-2 mr-2" ref={this.DropdownList}>
                                      <Label>Gender</Label>
                                      <DropdownList filter
                                                    placeholder=""
                                                    data={[
                                                      {
                                                        name: 'Male',
                                                        gender: 'male'
                                                      },
                                                      {
                                                        name: 'Female',
                                                        gender: 'female'
                                                      }
                                                    ]}
                                                    valueField="gender"
                                                    defaultValue={gender}
                                                    textField="name"
                                                    data-name={'gender'}
                                                    onChange={this.handleBankChange}
                                      />

                                    </div>
                                  </Col>
                              }
                            </Row>
                            <Row>
                              <Col xs="12" lg="6">
                                <Field
                                    name="email"
                                    type="email"
                                    component={renderField}
                                    label="Username"
                                    className="form-control"
                                />
                              </Col>
                              <Col xs="12" lg="6">
                                <Field
                                    name="password"
                                    type="password"
                                    component={renderField}
                                    label="Password"
                                    className="form-control"
                                />
                              </Col>
                            </Row>
                            <br />
                            <Field name="active" component={renderCheckbox} color="primary" label="Active" />
                          </fieldset>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12">
                          {!!error && <div className="form-error mt-3">{error && <strong>{error}</strong>}</div>}
                          <button type="submit" className="btn btn-primary mt-5">Submit</button>
                          {/*<button type="button" className="btn btn-light mt-5 ml-2" disabled={!this.state.clearChangesEnabled} onClick={this.handleClearChanges}>Clear changes</button>*/}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </form>
        }
        <Snackbar open={this.state.open} autoHideDuration={2000} message="Saved" onClose={() => this.setState(prev => ({...prev,open: false}))}
          action={
            <React.Fragment>
              <a className="text-white cursor-pointer mr-2" aria-label="close" onClick={this.handleClose}><i className="cil-x"></i></a>
            </React.Fragment>
          }
        />
      </div>
    )
  }
}

CustomerForm.propTypes = {
  pristine: PropTypes.bool,
  message: PropTypes.string,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  customer: PropTypes.object,
  brands: PropTypes.array,
  session: PropTypes.any
};

function mapStateToProps(state) {
  console.log('STATE');
  console.log(state);
    return {
      //formData: state.countries,
      initialValues: typeof(state.customerForm)!=='undefined' ? state.customerForm.customer : null,
      customer: typeof(state.customerForm)!=='undefined' ? state.customerForm.customer : null,
      brands: (typeof(state.brands)!=='undefined' && typeof(state.brands.result)!=='undefined') ? state.brands.result.items : [],
      banks: (typeof(state.banks)!=='undefined' && typeof(state.banks.result)!=='undefined') ? state.banks.result.items : [],
      session: state.session
    }
  };

const mapDispatchToProps = dispatch => bindActionCreators({getCustomer, addCustomer, updateCustomer, resetCustomer, getBrands, checkAuth, getBanks}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({
   form: 'customerForm', // a unique identifier for this form,
   enableReinitialize: true,
   validate
})(CustomerForm))