import React, {Component} from 'react';
import { PropTypes, array, bool } from 'prop-types';
import { Field, FormSection, reduxForm } from 'redux-form'

import { Form, Input, Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import Snackbar from '@material-ui/core/Snackbar';

import SimpleLineIcon from 'react-simple-line-icons';
import { renderField, renderTextareaField, renderSelectField, renderCheckbox } from '../../shared/components/form-field/ReduxFormFields';

import { reset } from 'redux-form';
import { SubmissionError } from 'redux-form'
import { stopSubmit } from 'redux-form'

import { getCategory, addCategory, updateCategory, resetCategory } from '../../actions/categoryActions';
import { checkAuth } from '../../actions/sessionActions';
import { validate } from './validate';

import './CategoryForm.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Redux Store
import configureStore from '../../shared/redux/configureStore';
import {backOnListPage} from '../../shared/utils/backOnListPage';
// Configuring Redux Store
const store = configureStore(window.initialState);

class CategoryForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      open: false,
      create: false,
      clearChangesEnabled: true
    }
    this.submit = this.submit.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount(props) {
    console.log('componentDidMount');
    const {getCategory, addCategory, updateCategory} = this.props;
    if(this.state.id) {
      getCategory(this.state.id);
    }

    /* JWT is stored in memory */
    /*
    const {session} = this.props
    if(typeof(session.jwt)=='undefined') {
      sessionStorage.removeItem('jwt');
      this.setState({session: null});
      this.props.history.push({pathname: '/login'});
      window.location.href="/login";
    }
    */
  }

  componentDidUpdate(prevProps) {
    console.log('componentDidUpdate')
    console.log(this.props);
     if(!this.props.match.params.id) {
      if(!this.state.create) {
        //this.props.dispatch(reset('categoryForm'));  // requires form name
        this.props.initialize();
        store.dispatch(resetCategory());
        this.setState({create: true});
      }

    }
  }

  // Save Category
  submit(values) {
    var action;
    if(typeof(values.active)=='undefined') {
      values.active = false;
    }
    if(this.state.create) {
      action = addCategory(values);
    }
    else {
      action = updateCategory(values);
    }
    store.dispatch(action).then((result) => {
      if(typeof(result)!='undefined') {
        this.setState({open: true});
        this.setState({clearChangesEnabled: false});
        backOnListPage(this.props.history, '/categories')
      }
  	}).catch((error) => {
  		throw new SubmissionError({_error:  error });
  	});
  }

  handleClose() {
    this.setState({open: false});
  }

  handleClearChanges = () => {
    this.props.reset();
  };

  render() {

    const session = this.props.session
    if(typeof(session.forceLogout)!="undefined" && session.forceLogout==1) {
      this.setState({session: null});
      window.location.href="/login";
    }

    const { error, handleSubmit, pristine, reset, submitting, message } = this.props

    return (
      <div className="animated fadeIn mt-5">
        <form onSubmit={handleSubmit(this.submit)}>
          <Row>
            <Col xs="12" lg="12">
              <Card>
                <CardHeader><i className="fa fa-align-justify"></i> Category details</CardHeader>
                <CardBody>
                  <Row>
                    <Col xs="12" lg="6">
                    <fieldset className="p-2 pb-5 pl-5 pr-5"><legend className="d-inline">Category</legend>
                      <Field
                        name="name.de"
                        type="text"
                        component={renderField}
                        label="Name DE"
                        className="form-control"
                        asterisk="*"
                      />
                      <Field
                        name="name.en"
                        type="text"
                        component={renderField}
                        label="Name EN"
                        className="form-control"
                        asterisk="*"
                      />
                      <Field
                        name="kpi_value"
                        type="number"
                        component={renderField}
                        label="KPI"
                        className="form-control"
                      />
                      <br />
                      <Field name="active" component={renderCheckbox} color="primary" label="Active" />
                    </fieldset>
                  </Col>
                  </Row>
                  <Row>
                     <Col xs="12">
                      {!!error && <div className="form-error mt-3">{error && <strong>{error}</strong>}</div>}
                      <button type="submit" className="btn btn-primary mt-5" disabled={pristine || submitting}>Submit</button>
                      {/*<button type="button" className="btn btn-light mt-5 ml-2" disabled={!this.state.clearChangesEnabled} onClick={this.handleClearChanges}>Clear changes</button>*/}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
        <Snackbar open={this.state.open} autoHideDuration={2000} message="Saved" onClose={() => this.setState({open: false})}
          action={
            <React.Fragment>
              <a className="text-white cursor-pointer mr-2" aria-label="close" onClick={this.handleClose}><i className="cil-x"></i></a>
            </React.Fragment>
          }
        />
      </div>
    )
  }
}

CategoryForm.propTypes = {
  pristine: PropTypes.bool,
  message: PropTypes.string,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  category: PropTypes.object,
  session: PropTypes.any
};

function mapStateToProps(state) {
  console.log('STATE');
  console.log(state);

  // if start create new category
  if (state?.form?.categoryForm && !state?.form?.categoryForm?.values) {
    state.form.categoryForm.values = { kpi_value: 0.0900 }
  }

  if (
    (state?.form?.categoryForm?.values?.name_translations?.de && !state?.form?.categoryForm?.values?.name?.de) ||
    (state?.form?.categoryForm?.values?.name_translations?.en && !state?.form?.categoryForm?.values?.name?.en)
  ) {
    state.form.categoryForm.values.name = state.form.categoryForm.values.name_translations
  }

  return {
      //formData: state.categories,
      initialValues: typeof(state.categoryForm)!=='undefined' ? state.categoryForm.category : null,
      category: typeof(state.categoryForm)!=='undefined' ? state.categoryForm.category : null,
      session: state.session
    }
  };

const mapDispatchToProps = dispatch => bindActionCreators({getCategory, addCategory, updateCategory, resetCategory, checkAuth}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({
   form: 'categoryForm', // a unique identifier for this form,
   enableReinitialize: true,
   validate
})(CategoryForm))