export function historyPagination(history ,page, perPage) {
    const pathname = history.location.pathname;
    const searchParams = new URLSearchParams(history.location.search);

    searchParams.set('page', page);
    searchParams.set('perPage', perPage);

    history.push(pathname + '?' + searchParams.toString());

    // localStorage.set('')
}