// Dependencies
import axios from 'axios';
import { API_URL, X_API_KEY, X_API_VERSION } from '../conf.js';
import { getCookie } from '../shared/utils/cookies';
import { axiosInterceptors } from '../shared/utils/axiosInterceptors';
import { objectToFormData } from 'object-to-formdata';

// Action Types
import {
  GET_BRAND_REQUEST,
  GET_BRAND_SUCCESS,
  GET_BRAND_ERROR,
  GET_BRANDS_REQUEST,
  GET_BRANDS_SUCCESS,
  GET_BRANDS_ERROR,
  ADD_BRAND_REQUEST,
  ADD_BRAND_SUCCESS,
  ADD_BRAND_ERROR,
  DELETE_BRAND_REQUEST,
  DELETE_BRAND_SUCCESS,
  DELETE_BRAND_ERROR,
  UPDATE_BRAND_REQUEST,
  UPDATE_BRAND_SUCCESS,
  UPDATE_BRAND_ERROR, GET_LOGS_REQUEST, GET_LOGS_ERROR, GET_LOGS_SUCCESS
} from './actionTypes';

// Base Actions
import { request, received, error } from '../shared/redux/baseActions';

const options = {
  /**
   * include array indices in FormData keys
   * defaults to false
   */
  indices: false,

  /**
   * treat null values like undefined values and ignore them
   * defaults to false
   */
  nullsAsUndefineds: false,

  /**
   * convert true or false to 1 or 0 respectively
   * defaults to false
   */
  booleansAsIntegers: false,
};

const getBrand = (params) => dispatch => {
  dispatch(request(GET_BRAND_REQUEST));
  var id = '';
  if (typeof (params) !== 'undefined') {
    id = params;
  }
  // Axios Data
  const axiosData = {
    method: 'GET',
    url: API_URL + '/brands/' + id,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'X-Api-Version' : X_API_VERSION,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(GET_BRAND_SUCCESS, response.data)))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(GET_BRAND_ERROR));
    });
};

const getLogs = (params) => dispatch => {
  dispatch(request(GET_LOGS_REQUEST));
  var perPage = 50;
  var page = 1;
  console.log('GET_LOGS');
  console.log(params);
  var url = API_URL + '/brands/';
  if (typeof(params) == 'undefined') {
    params = {};
    params['order[name]']='ASC';
  }
  var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
  console.log(queryString);
  url += '4/events';
  // Axios Data
  const axiosData = {
    method: 'GET',
    url: url,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'X-Api-Version' : X_API_VERSION,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  // If everything is correct we dispatch our received action
  // otherwise our error action.
  return axiosInstance(axiosData)
      .then(response => dispatch(received(GET_LOGS_SUCCESS, { items: response.data['items'], meta: response.data["meta"] })))
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log('AXIOS ERROR:', err.response);
        dispatch(error(GET_LOGS_ERROR));
      });
};

const getBrands = (params) => dispatch => {
  dispatch(request(GET_BRANDS_REQUEST));
  var perPage = 50;
  var page = 1;
  console.log('GET_BRANDS');
  console.log(params);
  var url = API_URL + '/brands?';
  if (typeof(params) == 'undefined') {
    params = {};
    params['order[name]']='ASC';
  }
  var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    console.log(queryString);
    url += queryString;
  // Axios Data
  const axiosData = {
    method: 'GET',
    url: url,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'X-Api-Version' : X_API_VERSION,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  // If everything is correct we dispatch our received action
  // otherwise our error action.
  return axiosInstance(axiosData)
    .then(response => dispatch(received(GET_BRANDS_SUCCESS, { items: response.data['items'], meta: response.data["meta"] })))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(GET_BRANDS_ERROR));
    });
};

const addBrand = (params) => dispatch => {
  dispatch(request(ADD_BRAND_REQUEST));
  var id = '';
  if (typeof (params) !== 'undefined') {
    id = params;
  }
  console.log('ADD_BRAND');
  console.log(params);
  params.address_attributes = params.address
  params.contact_attributes = params.contact
  if(typeof(params.tags)!='undefined') {
    params.tags = params.tags.length > 0 ? params.tags.join(',') : '';
  }
  else {
    params.tags = '';
  }

  // Axios Data
  const axiosData = {
    method: 'POST',
    url: API_URL + '/brands',
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'X-Api-Key': X_API_KEY,
      'X-Api-Version' : X_API_VERSION,
      'Authorization': 'Bearer ' + getCookie("_str")
    },
    data: formData(params)
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(ADD_BRAND_SUCCESS, typeof(response.data['form'])!='undefined' ? response.data['form'] : response.data )))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(ADD_BRAND_ERROR, err.response));
    });
};

const updateBrand = (params) => dispatch => {
  dispatch(request(UPDATE_BRAND_REQUEST));
  var id = '';
  if (typeof (params) !== 'undefined') {
    id = params;
  }
  params = { ...params, ...{ address_attributes: params.address } }
  params = { ...params, ...{ contact_attributes: params.contact } }

  params = { ...params, ...{ image1: (params?.image1 || [])[0] || null } }
  params = { ...params, ...{ image2: (params?.image2 || [])[0] || null } }
  params = { ...params, ...{ image3: (params?.image3 || [])[0] || null } }

  params = { ...params, ...{ logo: params?.logo ? params?.logo : null } }

  if(typeof(params.tags)!='undefined') {
    params.tags = params.tags.join(',');
  } else {
    params.tags = '';
  }

  console.log(params, 'PARAMS')

  if(params.parent_brand)  {
    params.parent_id = params?.parent_brand[0]?.id
    delete params?.parent_brand
  }
  // if (params?.parent_brand?.length > 1) params.parent_id = params?.parent_id[1]?.id
  //
  // if ((params.parent_id || [])[0]?.id) {
  //   params['parent_id'] = (params['parent_id'] || [])[0]?.id ? params['parent_id'][0].id : null
  // }

  console.log(params, 'end');

  // Axios Data
  const axiosData = {
    method: 'PATCH',
    url: API_URL + '/brands/' + params.id,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'X-Api-Key': X_API_KEY,
      'X-Api-Version' : X_API_VERSION,
      'Authorization': 'Bearer ' + getCookie("_str")
    },
    data: formData(params)
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(UPDATE_BRAND_SUCCESS, response.data['form'])))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(UPDATE_BRAND_ERROR, err.response));
    });
};

const deleteBrand = (id) => dispatch => {
  dispatch(request(DELETE_BRAND_REQUEST));
  // Axios Data
  const axiosData = {
    method: 'DELETE',
    url: API_URL + '/brands/' + id,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'X-Api-Version' : X_API_VERSION,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(DELETE_BRAND_SUCCESS, response.data)))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(DELETE_BRAND_ERROR, err.response));
    });
};

const resetBrand = () => dispatch => {
  dispatch(received(ADD_BRAND_SUCCESS, []))
};

function formData(params) {
  if(params.logo!=null && typeof(params.logo)==='object') {
    params.logo = params.logo[0];
  }
  else {
    delete params.logo;
  }
  if(params.logo_2x!=null && typeof(params.logo_2x)==='object') {
    params.logo_2x = params.logo_2x[0];
  }
  else {
    delete params.logo_2x;
  }

  const formData = objectToFormData(
    params,
    options, // optional
    //existingFormData, // optional
    //keyPrefix, // optional
  );

  formData.append('terminal_ids', params.terminal_ids);

  return formData;

}

export { getBrand, getBrands, addBrand, updateBrand, resetBrand, deleteBrand, getLogs };
