import {
    GET_USERS_SUCCESS,
    GET_USERS_ERROR,
    GET_USERS_REQUEST,
    GET_TRANSACTIONS_REQUEST,
    GET_TRANSACTIONS_SUCCESS, GET_TRANSACTIONS_ERROR
} from './actionTypes'
import {getUsersList} from './brandUpdateActions';
import {error, received, request} from '../shared/redux/baseActions';
import {API_URL, X_API_KEY} from '../conf';
import {getCookie} from '../shared/utils/cookies';
import axios from 'axios';
import {axiosInterceptors} from '../shared/utils/axiosInterceptors';

export function usersActions(params) {
    return async (dispatch) => {
        try {
            dispatch({
                type : GET_USERS_REQUEST
            });

            // await api.cart.list(session);
            const list = await getUsersList(params);

            dispatch({
                type :  GET_USERS_SUCCESS,
                data : list
            });
        } catch (err) {
            console.log('[ERROR] GET_USERS_ERROR', err);
            dispatch({
                type : GET_USERS_ERROR,
                data : err
            });
            throw (err);
        }
    };
}

export function resetUsersActions() {
    return async (dispatch) => {
        try {
            dispatch({
                type : GET_USERS_REQUEST
            });

            dispatch({
                type :  GET_USERS_SUCCESS,
                data : []
            });
        } catch (err) {
            console.log('[ERROR] GET_USERS_ERROR', err);
            dispatch({
                type : GET_USERS_ERROR,
                data : err
            });
            throw (err);
        }
    };
}

export const getClicks = (params) => {
    let perPage = 10;
    let page = 1;
    var url = API_URL + '/click_histories?';
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    url += queryString;
    // Axios Data
    const axiosData = {
        method: 'GET',
        url: url,
        headers: {
            //Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Api-Key': X_API_KEY,
            'Authorization': 'Bearer ' + getCookie("_str")
        }
    };
    const axiosInstance = axios.create();
    axiosInterceptors(axiosInstance);
    return axiosInstance(axiosData)
        .then(response => ({ items: response.data['items'], meta: response.data["meta"] }))
        .catch(err => {
            // eslint-disable-next-line no-console
            console.log('AXIOS ERROR:', err.response);
        });
};
